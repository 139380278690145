.mobileNav, .navButton{
  display: none;
  @media screen and (max-width: 768px){
    display: block;
  }
}
#topNav{
  @media screen and (max-width: 768px){
    display: none;
  }
}




.mobileNav li{
  position: relative;
  &.selected{
    > a{
      background-color: $color-md-lt;
      color: $color-dk-dk;
    }
  }
}
.mobileNav li a{
    background-color: lighten($color-dk-dk, 10%);
    width: 100%;
    display: block;
    padding: 8px;
    text-align:left;
    color: #FFF;
    font-size: 13px;
    text-transform: uppercase;
    font-weight: 600;
    transition: all ease .3s;
    &:hover{
      background-color: $color-md-lt;
      color: $color-dk-dk;
    }
}
.mobileNav li li a{
  background-color: lighten($color-dk-dk, 5%);
}
.mobileNav li + li{
    border-top: 1px solid $color-dk-dk;
}
.mobileNav ul ul{
  border-top:1px solid $color-dk-dk;
  display: none;
}
.mobileNav .dropdown li a {
    padding-left: 1.75em;
}

.nodeToggle {
    width: 32px;
    height: 32px;
    position: absolute;
    right: 0;
    top: 0;
    border-left: 1px solid #333;
}
.navButton{
  width: 32px;
  height: 32px;
}



.mobileNav {
  // display: none;
  position: absolute;
  z-index: 1003;
  top: 40px;
  margin: 0;
  width: 100%;
  max-height: 0;
  padding-top: 0px;
  overflow: hidden;
  transition: all ease 300ms;
}

.mobileNav.expanded {
  border-bottom: 5px solid $color-md-lt;
  max-height: 1000px;
  transition: all ease 1s;
}
//
// .mobileNav ul {
//   list-style-type: none;
//   white-space: nowrap;
//   margin: 0;
//   padding: 0;
//   background: #eee;
//   border-top: 1px solid #cecece;
// }
//
// .mobileNav > ul {
//   border-bottom: 1px solid #cecece;
// }
//
// .mobileNav li {
//   position: relative;
//   text-align: left;
//   border-bottom: 1px solid #cecece;
// }
//
// .mobileNav li:last-child {
//   border-bottom: none;
// }
//
// .mobileNav a {
//   color: #161616;
//   background-color: #efefef;
//   font-size: 15px;
//   line-height: 1;
//   text-transform: uppercase;
//   text-decoration: none;
//   display: block;
//   padding: 11px 15px 8px;
/*
  -webkit-transition: all ease 300ms;
          transition: all ease 300ms;
*/
// }

// .mobileNav a:hover {
//   color: #fff;
//   background-color: #0a0a0a;
/*
  background-color: rgba(0,0,0,0.10);
  box-shadow: inset 0 0 6px rgba(0,0,0,0.50);
*/
// }

// .mobileNav li li a {
//   background-color: #e3e3e3;
//   padding-left: 20px;
// }
//
// .mobileNav li li li a {
//   background-color: #d3d3d3;
//   padding-left: 40px;
// }

.mobileNav ul ul {
  display: none;
}

/*
.mobileNav li.mobileToggle {
  position: relative;
}
*/
.nodeToggle {
  position: absolute;
  z-index: 99;
  top: 0;
  bottom: 0;
  right: 0;
  width: 34px;
  height: 34px;
  display: block;
  border-left: 1px solid $color-dk-dk;
  background: $color-md-lt;
  cursor: pointer;
  -webkit-transition: all ease 300ms;
          transition: all ease 300ms;
}

.nodeToggle:before,
.nodeToggle:after {
  position: absolute;
  -webkit-transition: all ease 300ms;
          transition: all ease 300ms;
}

.nodeToggle:before {
  content: "";
  top: 16px;
  left: 12px;
  width: 10px;
  height: 2px;
  background: $color-dk-dk;
}

.nodeToggle:after {
  content: "";
  top: 12px;
  left: 16px;
  width: 2px;
  height: 10px;
  background: $color-dk-dk;
}

.nodeToggle:hover,
.nodeToggle.expanded {
  background: darken($color-md-lt, 10%);
}

.nodeToggle:hover:before,
.nodeToggle:hover:after,
.nodeToggle.expanded:before,
.nodeToggle.expanded:after {
  background: #fff;
}

.nodeToggle.expanded:before,
.nodeToggle.expanded:after {
  /*   opacity: 0; */
  -webkit-transform: rotate(45deg) scale(1.1);
      -ms-transform: rotate(45deg) scale(1.1);
          transform: rotate(45deg) scale(1.1);
}

.navButton {
  box-sizing: border-box;
  position: absolute;
  z-index: 1001;
  top: 5px;
  right: 15px;
  width: 30px;
  height: 30px;
  padding: 0;
  cursor: pointer;
  -webkit-transition: all ease 0.3s;
          transition: all ease 0.3s;
}

.navButton:active {
  background: none;
}

.navButton .bar,
.navButton .bar:before,
.navButton .bar:after {
  display: block;
  width: 100%;
  height: 4px;
  background-color: $color-md-lt;
  -webkit-transition: top 0.2s 0.2s,  bottom 0.2s 0.2s,  -webkit-transform 0.2s, background 0.2s;
          transition: top 0.2s 0.2s,  bottom 0.2s 0.2s,  transform 0.2s, background 0.2s;
}

.navButton .bar {
  margin: 13px 0;
}

.navButton .bar:before,
.navButton .bar:after {
  content: "";
  position: absolute;
}

.navButton .bar:before {
  top: 4px;
}

.navButton .bar:after {
  bottom: 4px;
}

.navButton:hover .bar,
.navButton:hover .bar:before,
.navButton:hover .bar:after {
  background-color: darken($color-md-lt, 10%);
}

.navButton.expanded .bar {
  background-color: transparent;
}

.navButton.expanded .bar:before,
.navButton.expanded .bar:after {
  -webkit-transition: top 0.2s,  bottom 0.2s, -webkit-transform 0.2s 0.2s, background 0.2s;
          transition: top 0.2s,  bottom 0.2s, transform 0.2s 0.2s, background 0.2s;
}

.navButton.expanded .bar:before {
  top: 13px;
  -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
          transform: rotate(45deg);
}

.navButton.expanded .bar:after {
  bottom: 13px;
  -webkit-transform: rotate(-45deg);
      -ms-transform: rotate(-45deg);
          transform: rotate(-45deg);
}

// @media (max-width: 980px) {
//   .deviceType {
//     position: relative;
//     z-index: 8;
//   }
//
//   #topNav {
//     display: none;
//   }
//
//   .mobileNav {
//     display: block;
//   }
//
//   .mobileNav.expanded {
//     display: block;
//   }
//
//   .navButton {
//     display: block;
//   }
// }
