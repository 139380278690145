.siteContainer,
#siteFooter,
#siteFooter > ul{
  width: 100%;
  max-width: 1000px;
}
#panelTwo{
  width:80%;
  box-sizing: border-box;
  margin-left: 0;
  padding-left: 15px;
  @media screen and (max-width:768px){
    width: 100%;
    padding-left: 0;
  }
}
#panelOne{
  width: 20%;
  box-sizing: border-box;
  @media screen and (max-width:768px){
    width: 100%;
    order: 2;
  }
}
#pageContentContainer{
  @media screen and (max-width: 768px){
    display: flex;
    flex-direction: column;
  }
}

.layoutContainer{
  .pe50, .pe33, .pe66, .pe25{
    @media screen and (max-width: 670px){
      width:100%;
    }
  }
  &.threeColumn502525, .threeColumn252550{
    .pe25{
      @media screen and (min-width: 450px) and (max-width: 670px){
        width: 50%;
      }
    }
  }
}

#topNav{
  @media screen and (max-width: 768px){

  }
}


#siteHeader > h1{
  font-size: 0;
}



@media screen and (max-width: 768px){
  #siteHeader .adminToggle, #siteHeader #adminNav{ display: none;}
}


// Element Level

.textBlockElement img{
  max-width: 100%;
}
