/*General Styles*/
html {
    overflow-x: hidden;
}
body {
	background:none;
}
#siteHeader, html, #siteContainer2.one_panel #panelTwo, #siteContainer2.one_panel #yieldContent {
	background: none;
}
#pageContentContainer {
	border-top: 5px solid #fec600;
}
#home #pageContentContainer {
	background: #e6e6e6;
	border-top: none;
	padding: 15px;
}
#accountNav .currentSite a {
	display: none;
}
.pageElement {
	background: #fff;
}
#accountNav {
    background: none repeat scroll 0% 0% rgb(255, 255, 255);
    display: table;
    position: fixed;
    bottom: 0px;
    left: 0px;
    z-index: 500;
}
/*
.layoutContainer {
    margin-right: -25px;
}
.layoutContainer .pageElement, .layoutContainer .hrElement {
    margin: 0px 25px 25px 0px;
}
*/
.cmsPageStatus ul {
	width: 300px;
}
.cmsPageStatus {
	margin-top: 50px;
}
.rapidTabsConstruction {
	border-top: none;
	padding: 0px;
}
#displayBodyHeader {
    display: none;
}
.addPageElement {
	text-align: center;
}


/*img element*/
body .cutline {
    color: #00498e;
	font-size: 17px;
    font-style: normal;
    margin: 0px;
    padding: 15px 5px 15px;
    text-transform: uppercase;
    font-weight: 600;
    text-align: center;
    margin-top: -13px;
}




/*h3 and aggregator styles*/
.textBlockElement h3, .aggregatorElement h3, .mailSubscribeElement h3, .reportTableElement h3, .tableElement h3, .pollElement h3 {
    font: 200 18px/20px sans-serif;
	color: #FFF;
	text-transform: uppercase;
	background: none repeat scroll 0% 0% #082387;
	padding: 4px 10px 1px 15px;
	font-weight: 100;
}
.aggregatorElement .newsItemElement a img {
    height: 100%;
}
.newsAggregatorElement .newsItemHeader > a {
	height: 75px;
	width: 75px;
	overflow: hidden;
	display: block;
	float: left;
	margin: 0px 15px 5px;
}
.newsItemElement h2 {
    font: bold 16px/18px sans-serif;
    color: rgb(8, 35, 135);
    margin: 5px 0px 5px;
}
.newsItemElement h2 a {
    color: rgb(8, 35, 135) !important;
}
.collectorElement .item, .aggregatorElement .item, .aggregatorElement .vevent {
	border-top: none;
	border-bottom: 1px solid #CCC;
}
.aggregatorElement .vevent {
    padding: 12px 10px 0px 15px;
	margin: 0px;
}
.newsItemElement h4 {
	display: none;
}
.newsItemElement .newsText {
	display: none;
}
.newsAuthor, .newsDate, .newsTags {
    font-size: 12px;
    color: rgb(90, 90, 90);
    line-height: 18px;
}
.summary a {
	color: #336799;
	font-weight: 600;
}
.description p a {
	color: #336799;
}
.aggregatorElement a img.ics_file_download, .tags {
	display: none;
}
.aggregatorElement .vevent .time, .aggregatorElement .vevent .location {
    font-weight: 500;
    color: #595959;
}
.location {
	display: block !important;
}
abbr.dtstart, abbr.dtend {
    border-bottom: none;
}
.dateImage .month {
    font-size: 10px;
	font-weight: 600;
	margin: 0px 4px;
	color: #082387;
	background: none repeat scroll 0% 0% #CCC;
	padding-top: 3px;
	line-height: 12px;
}
.dateImage .date {
    font-size: 20px;
    line-height: 140%;
    font-weight: 600;
    color: #082387;
}
.eventAggregatorElement .item {
    border-top: none !important;
}
.icon {
    padding: 2px 0px 2px 20px;
    background-image: none;
    font-weight: 600;
}
.emailLink.icon {
	background-image: url(http://app-assets3.sportngin.com/app_images/link_icons.png?1427834787);
}
.extendedOptions {
	padding: 15px 20px !important;
	color: #336799 !important;
	font-weight: 600;
}
.extendedOptions .icon {
	color: #336799;
	padding: 2px 6px !important;
}
.newsAggregatorElement .item {
    padding: 11px 0px;
}
.eventAggregatorElement h3 {
	margin-bottom: 0px;
}
.newsAggregatorElement h3 {
	margin-bottom: -5px;
}
.eventAggregatorElement .extendedOptions {
	padding:50px 20px 15px 20px !important;
}
.aggregatorElement .vevent h5, .vevent .location, .vevent .time, .aggregatorElement .description {
    font-size: 12px;
}
.eventAggregatorElement .vevent ul {
	line-height: 20px;
}
.readMore .button-small {
	background: none;
	border: 1px solid #082387;
	color: #082387;
	text-shadow: none;
}
.condensed li a {
	color: #082387;
}
.condensed .teaser {
	display: none;
}

/*Header*/
#siteHeader {
    height: 200px;
	background: none repeat scroll 0% 0% transparent
}
#logo {
	position: absolute;
	top: 85px;
	left: -40px;
	z-index: 1000;
	width: 200px;
}

.Htitle1 {
	text-indent: 0px !important;
	font-size: 31px;
	color: #FFF;
	font-weight: 400;
	text-transform: uppercase;
	letter-spacing: 2px;
	margin-left: 169px;
	transform: scale(1, 1.4);
}
.Htitle2 {
	margin-top: 75px;
	margin-bottom: 7px;
	font-size: 13px;
	font-style: normal;
	color: #FFF;
	text-transform: uppercase;
	letter-spacing: 2px;
	margin-left: 170px;
	font-weight: 100;
}
.faceicon {
	position: absolute;
	z-index: 500;
	top: 0px;
	right: 30px;
}
.tweeticon {
	position: absolute;
	z-index: 500;
	top: 0px;
	right: 0px;
}

/*Nav*/
#topNav {
    height: 45px;
	background: none repeat scroll 0% 0% rgb(8, 35, 135);
	border-bottom: 7px solid #fec600;
}
#topNav table {
    width: 100%;
}


/*Hide Trash*/
td#top_nav_node_page_node_1652581 {
  display: none;
}



#topNav td {
	height: 45px;
	width: auto;
}
#topNav td:hover {
	background: #04134b;
}
#topNav td.selected {
	background: #FEC600;
}

#topNav tr td a {
    font-size: 14px;
    text-transform: uppercase;
    font-weight: 100;
	color: #fff;
}
#topNav tr td.selected a {
    font-size: 14px;
    text-transform: uppercase;
    font-weight: 100;
    color: #082387;
}



#topNav tr td.selected ul li a {
	color: #e3e3e3;
}
#topNav tr td ul li a {
	color: #e3e3e3;
	background: #04134b;
	border: none;
	padding: 6px 15px 6px 30px !important;
	width: 200px !important;
	font-weight: 300 !important;
}
#topNav td ul {
	top: 45px;
	padding-top: 30px;
	padding-bottom: 10px;
	background: none repeat scroll 0% 0% #04134b;
	box-shadow: none;
}
#topNav td ul ul {
	top: 0px;
	padding-top: 0px;
	padding-bottom: 10px;
	background: none repeat scroll 0% 0% #336799;
	box-shadow: none;
}
#topNav td ul ul li a {
	background: #336799;
	border: none;
}
/* #topNav td ul li:hover, #topNav td ul li.hover */
#topNav td ul li:hover > a, #topNav td ul li.hover > a, #topNav td.selected ul li:hover > a, #topNav td.selected ul li.hover > a {
	border: none;
	background: #fec600;
	color: #000;
}
#topNav td ul ul li:hover > a, #topNav td ul ul li.hover > a {
	border: none;
	background: #fff !important;
	color: #000;
}
.pointer::after {
	display: none;
    background-position: inherit;
    position: absolute;
    top: 0px;
    content: "";
    width: 0;
    height: 0;
    left: 100%;
    border-top: 21.5px solid transparent;
    border-left: 20px solid #fec600;
    border-bottom: 21px solid transparent;
    z-index: 5000000000;
}
.pointer:hover::after {
	display: block;
}
#topNav ul .dropdown-disclosure {
	display: none;
}

/*Slideshow*/
.newsSlideShow-more, .newsSlideShow-thumbnails img {
	display: none;
}
.layout_classic .newsSlideShow-thumbnails li {
	width: 30px;
}
.layout_classic .newsSlideShow-thumbnails {
    padding: 0px;
    width: 100%;
}
.newsSlideShow-thumbnails {
	text-align: right;
	margin: 0px 0px 15px -20px;
}
.layout_classic .newsSlideShow-headline {
	padding-bottom: 0px;
	background: rgba(255, 255, 255, 0.85);
}
.newsSlideShow-headline h1 {
    font-size: 24px;
	line-height: 32px;
	font-weight: 600;
	background: none repeat scroll 0% 0% transparent !important;
	padding: 25px 60px 30px 15px !important;
}
.newsSlideShow-headline h1 a {
    color: #082387;
}
.layout_classic .newsSlideShow-headline p {
	display: none;

}
.layout_classic .newsSlideShow-headline p span {
    color: #000;
    font-size: 16px;
	line-height: 29px;
	padding-right: 15.6em;
	text-align: left;
	white-space: normal;
}
.layout_classic .newsSlideShow-headline h1 a {
	height: auto;
}
.layout_classic .newsSlideShow-thumbnails a {
	border: 2.5px solid #336799;
}
.layout_classic .newsSlideShow-thumbnails a.current, .layout_classic .newsSlideShow-thumbnails a:hover {
	border: 2.5px solid #fec600;
}

/*Tabb Elements*/
.tabbedElement {
	background: transparent;
}
.tabContainer {
  	clear: left;
  	background: #fff;
  	border-top: 5px solid #fec600;
}
.TabPic {
  	width: 47%;
  	float: left;
}
.TabPic .heroPhotoElement {
	margin: 15px 0px 10px 15px;
}
.contentTabs {
	height: 0px;
}
.contentTabs li {
	padding-right: 0px;
    float: left;
    line-height: 25px;
    background: none repeat scroll 0% 0% rgb(255, 255, 255);
    margin: 0px 3px 0px 0px;
    padding: 2px 10px 0px 10px;
    padding-right: 15px;
}
.contentTabs li span {
	padding-left: 0px;
	background-image: none;
	margin-bottom: -3px;
}
.contentTabs li span a {
    text-decoration: none;
    outline: medium none;
    font-size: 18px;
    font-weight: 300;
    text-transform: uppercase;
    color: #082387;
}
.contentTabs .selected {
	background: #082387;
}
.contentTabs li.selected span a {
	color: #fff !important;
}
.TabButton .linkElement h4 {
	padding: 0px 15px;
}
.TabButton .linkElement h4 a {
	background: none repeat scroll 0% 0% rgb(254, 198, 0);
	text-transform: uppercase;
	text-align: center;
	color: #082387;
	padding: 17px 0px;
}
.TabButton .pageElement {
	margin-bottom: 0px;
}

/*footer styles*/
#siteFooter {
	background: #082387;
	border-top: 5px solid #fec600;
}
#siteFooter ul {
	padding: 31px 0px;
	text-align: center;
}
#siteFooter ul li {
	color: #fff;
	font-weight: 200;
	font-size: 13px;
}
#siteFooter ul li a {
	color: #fec600;
}
#siteFooter ul li#poweredByNGIN {
    display: none;
}

/*Sponsors*/
.Sponsors {
	background: #fff;
	margin-right: 0px;
	margin-bottom: 10px;
}
.SponsorHead .pageElement {
	margin: 0px;
}
.SponsorHead h3 {
	margin-bottom: 0px;
	font-size: 18px;
	padding: 5px 10px 2px 15px;
}
.Sponsors .SponsorIMG {
	display: table;
	float: left;
}
.SponsorIMG .pageElement {
	margin: 20px 17.5px;
	height: 65px;
}
.Guide .pageElement {
	margin-right: 15px !important;
}
.GuideTxt h4, .GuideTxt h1 {
	margin-bottom: 15px !important;
}
.SponsorIMG .heroPhotoElement img {
	max-height: 65px;
}

/*SubNav*/
.subNav h4, .relatedSections h4 {
    font-size: 16px;
    color: rgb(51, 103, 153);
    font-weight: 500;
    margin-bottom: 5px;
}
#parent_nodes, #sibling_nodes {
	background: #d0d2d4;
	padding: 7px 0px 20px 0px;
	border-top: 3px solid #FEC600;
}
#child_nodes {
	background: #d0d2d4;
	padding: 0px 0px 20px 0px;
	margin-top: -20px;
}
.subNav ul li, .relatedSections ul li {
	padding: 0px 20px;
	margin-bottom: 0px;
}
.subNav ul.parents li.selected a {
	color: #203D59 !important;
}
.subNav ul li.selected a {
	padding-left: 20px;
}
.subNav ul li:hover, .relatedSections ul li:hover {
	background: #fff url("http://assets.ngin.com/site_files/8127/i/arrow.png") no-repeat;
	background-position: 10px center;
}
.subNav ul li:hover > a, .relatedSections ul li:hover > a {
	color: rgb(51, 103, 153) !important;
}
.subNav ul.children li a, .relatedSections ul.siblings li a, .subNav ul li a, .relatedSections ul li a {
	color:#203d59;
	font-size: 13px;
	line-height: 32px;
	display: table-cell;
	padding: 0px;
	text-transform: uppercase;
	font-weight: 600;
}
.subNavContainer {
	margin: 5px 0px 0px -3px;
}
.subNavContainer .subNav li.selected {
	background: url("http://assets.ngin.com/site_files/8127/i/arrows.png") no-repeat;
	background-position: 20px center;
}
.subNavContainer .subNav li.selected:hover {
	background: #fff url("http://assets.ngin.com/site_files/8127/i/arrows.png") no-repeat;
	background-position: 20px center;
}
.subNavContainer .subNav li.selected:hover >a {
	color: rgb(51, 103, 153) !important;
}

/*Special Top Header Stuff*/

.toplogo {
	position: absolute;
	top: 90px;
	right: 0px;
}
.Lside {
	margin: 0px auto;
	width: 175px;
}
.Lside span {
	font-weight: bold;
	color: #fff;
}
.Lside p {
	font-size: 11px;
	text-transform: uppercase;
	font-weight: 200;
	font-family: sans-serif;
	color: rgb(236, 236, 236);
	text-align: center;
}
.Rside {
	width: 185px;
	margin: 0px auto;
}
.Rside img {
	margin: 0px 10px;
}
.edit_mode .subNav ul li.selected.lock {
    background-image: none;
}

$color-md-lt: #FEC600;
$color-md-dk: #082387;
$color-dk-dk: #030F41;


@import "_responsive-base";
@import "_responsive-nav";


.faceicon, .tweeticon{
  top: 50px;
}
#siteHeader {
    height: auto;
}
#siteHeader .adminToggle{ right: 215px; }
#siteHeader #adminNav{ right: 60px; bottom: auto; }

.header{
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-flow: row wrap;
  margin-top:80px;
  @media screen and (max-width: 870px){
    justify-content: center;
  }
}
.site-tagline{
  margin-left: 1em;
  margin-right: auto;
  h1{
    text-indent: 0px !important;
    font-size: 31px;
    color: #FFF;
    font-weight: 400;
    text-transform: uppercase;
    letter-spacing: 0px;
    transform: scale(1, 1.4);
    @media screen and (max-width: 450px){
      font-size: 6.9vw;
    }
  }
  p{
    font-size: 13px;
    font-style: normal;
    color: #FFF;
    text-transform: uppercase;
    letter-spacing: 2px;
    font-weight: 100;
  }
  @media screen and (max-width: 870px){
    margin: 0;
    margin-left: 1em;
  }
  @media screen and (max-width: 660px){
    margin: 1em auto;
    text-align:center;
  }
}
.site-gov{
  width: 185px;
  order: 3;
  > div{ text-align: center; }
  img{ margin: 0 8px; }
  p{
    font-size: 11px;
    text-transform: uppercase;
    font-weight: 200;
    font-family: sans-serif;
    color: #ececec;
    text-align: center;
    span{
      font-weight: bold;
      color: #fff;
    }
  }
  @media screen and (max-width: 870px){
    width:100%;
    margin-bottom: 1em;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-flow: row wrap;
    p{max-width: 185px;}
  }
  @media screen and (max-width: 500px){
    margin: 0 auto 1em auto;
  }
}
.site-logo{
  display: block;
  img{
    max-width: 200px;
    width: 100%;
  }
  @media screen and (max-width: 660px){
    width: 100%;
    text-align:center;
  }
}
